import { documentsApi, savefile } from '../api/api'

export const printDiplom = (order) => {
    documentsApi.printDiplom(order.id)
        .then(response => savefile(response, `Диплом (${order.protocol_num}) ${order.last_name} ${order.name} ${order.middle_name} (${order.id}).docx`))
}

export const printReference = (order) => {
    documentsApi.printReference(order.id)
        .then(response => savefile(response, `Справка ${order.last_name} ${order.name} ${order.middle_name} (${order.id}).docx`))
}

export const printInnerDocs = (order) => {
    documentsApi.printInnerDocs(order.id)
        .then(response => savefile(response, `Внутренние документы ${order.last_name} ${order.name} ${order.middle_name}.zip`))
}

export const printInnerDocsAll = (order) => {
    documentsApi.printInnerDocsAll(order.id)
        .then(response => savefile(response, `Внутренние документы ${order.last_name} ${order.name} ${order.middle_name}.docx`))
}

export const printAllPost = (order) => {
    documentsApi.printAllPost(order.id)
        .then(response => savefile(response, `Документы для почты С5 ${order.last_name} ${order.name} ${order.middle_name}.docx`))
}

export const printNotification119 = (order) => {
    documentsApi.printNotification119(order.id)
        .then(response => savefile(response, `Уведомление ${order.last_name} ${order.name} ${order.middle_name}.docx`))
}