const labels = {
    login: "Логин",
    password: "Пароль",
    signIn: "Войти",
    validate: {
        required: "Обязательное поле",
        unauthorized: "Неверный логин или пароль"
    }
}

export default labels