import React from 'react'
import { Badge, Layout, Menu } from 'antd'
import SubMenu from 'antd/lib/menu/SubMenu'
import { matchPath, withRouter } from 'react-router'
import { useSelector } from 'react-redux';
import { FileWordTwoTone, PieChartTwoTone, DollarCircleTwoTone } from '@ant-design/icons';
import { printDiplom, 
    printReference, 
    //printInnerDocs, 
    printInnerDocsAll, 
    printAllPost, 
    printNotification119  
  } from '../../helpers/documents';
import { ordersApi } from '../../api/api';
import { useState } from 'react';
import { useEffect } from 'react';

const SideBar = ({ orderMenuProps, ordersWarningsProps }) => {
    const { Sider } = Layout

    return ( 
      <Sider 
        theme="light" 
        className="site-layout-background" 
        width={ 300 }
        breakpoint="lg"
        collapsedWidth="0"
      > 
        <Menu
          mode="inline"
          //defaultOpenKeys={['documents']}
          style={{ height: '100%' }}
        >
          {
            orderMenuProps.avaible ? 
            <SubMenu key="documents" icon={ <FileWordTwoTone /> } title="Документы">
                <Menu.Item key="11">
                    <span onClick={ () => printDiplom(orderMenuProps.order) }>Скачать диплом</span>
                </Menu.Item>
                <Menu.Item key="12">
                    <span onClick={ () => printReference(orderMenuProps.order) }>Скачать справку</span>
                </Menu.Item>
                <Menu.Item key="13">
                    <span onClick={ () => printInnerDocsAll(orderMenuProps.order) }>Внутренние документы</span>
                </Menu.Item>
                <Menu.Item key="14">
                    <span onClick={ () => printAllPost(orderMenuProps.order) }>Почта</span>
                </Menu.Item>
                <Menu.Item key="15">
                    <span onClick={ () => printNotification119(orderMenuProps.order) }>Уведомление</span>
                </Menu.Item>
            </SubMenu> 
            : ''
          }
          {
            ordersWarningsProps.avaible ?
            <>
            <Menu.Item key="21" icon={ <PieChartTwoTone /> }>
              { 'Не учтены комиссии' }  
              <Badge 
                count={ ordersWarningsProps.ordersWithNotSubstructedCommissionCount } 
                style={ ordersWarningsProps.ordersWithNotSubstructedCommissionCount === 0 ? { 
                  backgroundColor: '#52c41a',
                  marginLeft: '10px'
                } : { marginLeft: '10px' } }
                showZero 
              />
            </Menu.Item>
            <SubMenu key="balances" icon={ <DollarCircleTwoTone /> } title={ <Badge offset={[5, 0]} dot>Балансы</Badge> }>
              { 
                Object.entries(ordersWarningsProps.balances)
                  .map(balance =>
                    <Menu.Item key={ balance[0] }>
                      <span>{ `${balance[0]}: ${ balance[1]} р.` }</span>
                    </Menu.Item>
                  ) 
              }
            </SubMenu>
            </>
            : ''
          }
        </Menu> 
      </Sider>
    )
}

const SideBarContainer = ({ location }) => {
  const order = useSelector(state => state.orders.currentOrder)
  const orderRoute = matchPath(location.pathname, {
    path: "/orders/:orderId",
    exact: true,
    strict: false
  })

  const [ordersWithNotSubstructedCommissionCount, setOrdersWithNotSubstructedCommissionCount] = useState(0)
  const [balances, setBalances] = useState({})

  useEffect(
    () => {
      ordersApi.getOrdersWithNotSubstructedCommission()
        .then(count => setOrdersWithNotSubstructedCommissionCount(count))
      ordersApi.getBalances()
        .then(result => setBalances(result)) 
    },
    []
  )

  return <SideBar 
    orderMenuProps={{ 
      avaible: orderRoute instanceof Object,
      order: order  
    }}
    ordersWarningsProps={{
      avaible: true,
      ordersWithNotSubstructedCommissionCount,
      balances
    }}
  />
}

export default withRouter(SideBarContainer)