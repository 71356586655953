const labels =  {
    fields_names: {
        name: "Имя",
        last_name: "Фамилия",
        middle_name: "Отчество",
        zip_code: "Индекс",
        region: "Регион",
        city: "Город",
        address_1: "Улица, дом",
        address_2: "Квартира", 
        birth_date: "Дата рождения",
        education_level: "Уровень образования",
        phone: "Телефон",
        email: "Email",
        course: "Курс",
        date_1: "Дата начала",
        date_2: "Дата окончания",
        term: "Количество часов",
        duration: "Длительность",
        duration_unit: "Дней/месяцев",   
        course_status: "Статус курса",
        diplom_status: "Статус диплома",    
        track: "Трек-номер",  
        pay_method: "Способ оплаты",  
        price: "Стоимость",       
        fee: "Комиссия",   
        price_received: "Получено от стоимости",  
        price_comments: "Комментарий к стоимости",  
        instalments: "Рассрочка", 
        instalments_payed: "Оплачено по рассрочке", 
        instalments_1st_pay: "1-й платёж по рассрочке",
        instalments_type: "Тип рассрочки",  
        add_info: "Дополнительная информация"
    },

    fields_placeholders: {
        name: "Имя",
        last_name: "Фамилия",
        middle_name: "Отчество",
        zip_code: "Индекс",
        region: "Регион",
        city: "Город",
        address_1: "Улица, дом",
        address_2: "Квартира", 
        birth_date: "Дата рождения",
        education_level: "Уровень образования",
        phone: "Телефон",
        email: "Email",
        course: "Курс",
        date_1: "Дата начала",
        date_2: "Дата окончания",
        term: "Количество часов",
        duration: "Длительность",
        duration_unit: "Дней/месяцев",   
        course_status: "Статус курса",
        diplom_status: "Статус диплома",    
        track: "Трек-номер",  
        pay_method: "Способ оплаты",  
        price: "Стоимость",       
        fee: "Комиссия",   
        price_received: "Получено от стоимости",  
        price_comments: "Комментарий к стоимости",  
        instalments: "Рассрочка", 
        instalments_payed: "Оплачено по рассрочке", 
        instalments_1st_pay: "1-й платёж по рассрочке",
        instalments_type: "Тип рассрочки",  
        add_info: "Дополнительная информация"
    }
}
                        
export default labels           